<script>
  import { onMount } from "svelte";
  import DescriptionBox from "../UI/DescriptionBox.svelte";
  
  import Text3d from "../Content/ExeFiles/3dText.svelte";
  import Gui from "../Content/ExeFiles/Gui.svelte";

  export let contentData = null;

  let exeData = null;
  let dynamicComponent = null;

  const componentMap = {
    'Text3D': Text3d,
    'gui': Gui,
  };

  
  onMount(() => {
    exeData = contentData;
    console.log(exeData);
    dynamicComponent = componentMap[exeData?.src];
    console.log(dynamicComponent);
    
    
  });
</script>

<div class="wrapper">
  <svelte:component this={dynamicComponent} />
  {#if exeData?.description}
    <DescriptionBox>
      {exeData?.description}
    </DescriptionBox>
  {:else}
    <DescriptionBox />
  {/if}
</div>

<style>
  .wrapper {
    height: 100%;
    overflow: hidden;
    position: relative;
  }
</style>
