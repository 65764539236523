<script>
  import { onMount, onDestroy } from "svelte";
  import ButtonRound from "../UI/ButtonRound.svelte";
  import DescriptionBox from "../UI/DescriptionBox.svelte";
  import supabase from '../config/supabaseClient';
  import { gsap } from "gsap";

  let player;
  let videoStatus = "";
  let currentVideo = null;
  let thumbnail = "";
  let playerReady = false;
  let duration = 0;
  let elapsedTime = 0;
  let currentPercent = 0;
  let playlists = [];
  let songList = [];
  let selectedPlaylist = "1";
  let kirby = "img/kirby-frames/1.png"
  let isKirbyPlaying = false;

  export let contentData = null;
  export let exportPage = "";

  let imgElement =  document.querySelector('.kirby-gif');

  let canvas;
  let ctx;
  let animation;
  let isPlaying = false;

  const spriteSheet = new Image();
  spriteSheet.src = "img/kirby-spritesheet.png"; // Single image containing all frames
  const frameWidth = 180;
  const frameHeight = 200;
  const totalFrames = 30;
  const fps = 40;
  let currentFrame = 0;

  function drawFrame(frame) {
    if (!ctx) return;
    ctx.clearRect(0, 0, frameWidth, frameHeight);
    ctx.drawImage(spriteSheet, frame * frameWidth, 0, frameWidth, frameHeight, 0, 0, frameWidth *0.7, frameHeight * 0.7);
  }

  function playKirby() {
    if (!animation) return;
    animation.play();
    isPlaying = true;
  }

  function pauseKirby() {
    if (!animation) return;
    animation.pause();
    isPlaying = false;
  }

  onMount(() => {   
    ctx = canvas.getContext("2d");

    // Ensure image is loaded before animation starts
    spriteSheet.onload = () => {
      drawFrame(0);

      animation = gsap.to({}, {
        repeat: -1,
        paused: true,
        duration: totalFrames / fps,
        onUpdate: function () {
          currentFrame = Math.floor(this.progress() * totalFrames);
          drawFrame(currentFrame);
        }
      });
    };
    const fetchPlaylists = async () => {
      const {data, error} = await supabase
        .from('playlists')
        .select('*')
        .order('created_at', {ascending: true});
  
      if(!error) {
        playlists = data;
      }
  
      fetchSongs();

    }

    fetchPlaylists();

    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    } else {
      onYouTubeIframeAPIReady();
    }

    setInterval(displayElapsedTime, 1000);
  });

  async function fetchSongs() {
    const {data, error} = await supabase
      .from('songs')
      .select('*')
      .eq('playlist', selectedPlaylist)
      .order('created_at', {ascending: true});

      
      
      if(error) {
        console.log('Error fetching songs', error);        
      } else {
        songList = data;        
      }    
  }

  function onYouTubeIframeAPIReady() {
    player = new YT.Player("player", {
      width: 335,
      height: 200,
      playerVars: {
        controls: 1,
        showinfo: 0,
        rel: 0,
        fs:0,
        origin: window.location.host,
        playsinline: 1,
        autoplay: 1,
        modestbranding: 1,
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });
  }

  function onPlayerReady(event) {
    player.cuePlaylist(songList.map((item) => item.videoId));
    playerReady = true;
  }

  async function onPlayerStateChange(event) {
    videoStatus = event.data;

    if (videoStatus === YT.PlayerState.PLAYING) {
      currentVideo = player.getPlaylistIndex();
      thumbnail = songList[currentVideo].thumb;
      duration = player.getDuration();      
      animation.play();     
      playKirby(); 
      isKirbyPlaying = true;
    } else {
      animation.pause();
      pauseKirby();
      isKirbyPlaying = false;
    }
    
    if(videoStatus === YT.PlayerState.ENDED) {      
      
      if (currentVideo == (songList.length-1)) {
        selectedPlaylist++;

        const mappedPlaylists = playlists.map((item) => item.id);

        if (mappedPlaylists.indexOf(selectedPlaylist) === -1) {          
          selectedPlaylist = playlists[0].id;
        }

        await fetchSongs();
        player.loadPlaylist(songList.map((item) => item.videoId));
      }

    }
  }

  function displayElapsedTime() {
    if(videoStatus === YT.PlayerState.PLAYING) {
      elapsedTime = player.getCurrentTime();
      currentPercent = elapsedTime && duration ? (elapsedTime * 100) / duration : 0;
    }
  }

  function playVideo() {
    if (videoStatus !== YT.PlayerState.PLAYING) {
      player.playVideo();
    } else {
      player.pauseVideo();
    }
  }

  function nextVideo() {
    player.nextVideo();
  }

  function previousVideo() {
    player.previousVideo();
  }

  async function updatePlaylist() {
    await fetchSongs();
    
    currentVideo = null;
    player.cuePlaylist(songList.map((item) => item.videoId));
  }

</script>

<div class="wrapper">

  <div class="thumbnail">
    <div id="player" class="round-border"></div>
    <!-- <img src={kirby} alt="Kirby" class="kirby-gif"> -->
    <canvas bind:this={canvas} class="kirby-gif" width="126" height="140"></canvas>
  </div>
  {#if playlists.length> 0}
  <div class="playlist-details">
    <div class="current-playlist">Current Playlist: {playlists.find((playlist) => playlist.id == selectedPlaylist ).title}</div>
    <div class="playlist-desc">{playlists.find((playlist) => playlist.id == selectedPlaylist ).description}</div>   
  </div>
  {/if}

  <div class="song-frame">
    <input type="range" class="duration-range" value={currentPercent} disabled/>
    <div class="song-info">
      {#if songList[currentVideo]?.title }
      <div class="song"><a href="{'https://www.youtube.com/watch?v=' + songList[currentVideo]?.id }" target="_blank">{songList[currentVideo]?.title}</a></div>
      {:else}
      <div class="song">No song playing </div>
      {/if}
      {#if currentVideo !== null}
        <div class="author">
          by {songList[currentVideo]?.author}
        </div>
      {/if}
    </div>
    <div class="player-actions">
      <ButtonRound on:click={previousVideo} disabled={!playerReady}
        ><i class="fa-solid fa-backward fa-fw"></i></ButtonRound
      >
      <ButtonRound on:click={playVideo} disabled={!playerReady}>
        {#if videoStatus !== YT.PlayerState.PLAYING}
          <i class="fa-solid fa-play fa-fw"></i>
        {:else}
          <i class="fa-solid fa-pause fa-fw"></i>
        {/if}
      </ButtonRound>
      <ButtonRound on:click={nextVideo} disabled={!playerReady}
        ><i class="fa-solid fa-forward fa-fw"></i></ButtonRound
      >
    </div>
  </div>

  <DescriptionBox buttonLabel="Playlists">
    <select
      name="slcTheme"
      bind:value={selectedPlaylist}
      on:change={updatePlaylist}
    >
    {#each playlists as playlist}
      <option value={playlist.id}> {playlist.title} </option>
    {/each}
    </select>

    <div class="playlist round-border">
      {#each songList as song, index}
        <div class="song" on:click={() => player.playVideoAt(index)} title="{song?.title} - {song?.author}">
          <div class="song-title" class:playing={currentVideo === index}>{song?.title} - {song?.author}</div>
          <div class="song-is-playing">
              {#if currentVideo === index}
                <i class="fa-solid fa-play"></i>
              {/if}
            </div>
        </div>
      {/each}
    </div>
    <div class="info">Audio player powered by Youtube Embed API</div>
  </DescriptionBox>
</div>

<style lang="scss">
  .wrapper {
    background-image: linear-gradient(
        30deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        150deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        30deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        150deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        60deg,
        #fff0df77 25%,
        transparent 25.5%,
        transparent 75%,
        #fff0df77 75%,
        #fff0df77
      ),
      linear-gradient(
        60deg,
        #fff0df77 25%,
        transparent 25.5%,
        transparent 75%,
        #fff0df77 75%,
        #fff0df77
      );
    width: 100%;
    height: 100%;
    background-size: 80px 140px;
    background-position:
      0 0,
      0 0,
      40px 70px,
      40px 70px,
      0 0,
      40px 70px;
    background-color: #fff8e9;
    position: relative;
  }
  .info {
    color: #181425;
    opacity: .6;
    text-align: center;
    margin-top: 10px;
  }

  .playlist-details {
    margin-top: 10px;
    padding: 0 5px;
    text-align: center;
    font-size: .8em;
    font-family: "Source Sans Pro" sans-serif;
    .current-playlist {
      font-weight: bold;
      color: var(--theme-color-dark);
    }
  }
  

  .playlist {
    margin-top: 10px;
    overflow: hidden;
    background-color: #fff;
    height: 150px;
    overflow-y:auto;
    .song {
      cursor: pointer;
      padding: 5px;
      font-size: 0.9em;
      border-bottom: solid 1px lightgray;
      display:flex;
      align-items: center;

      .song-title {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.playing {
          font-weight: bold;
          color: var(--theme-color-dark);
        }
      }

      .song-is-playing {
        width:20px;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  select {
    width: 100%;
  }

  .youtube-iframe {
    width: 100%;
  }

  .song-frame {
    border: 4px solid #181425;
    border-radius: 20px;
    padding: 5px 5px 10px 5px;
    margin: 10px 25px;
    background-color: #fff;
  }

  .thumbnail {
    padding: 1em 1em 0 1em;
    width: 100%;
    height: 228px;
    position: relative;

    .kirby-gif {
      position: absolute;
      bottom: -13px;
      right: 2px;
      // width: 230px;
      // height: 108px;
    }

    .thumb-no-video {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 1.5em;
      font-family: "Comfortaa", cursive;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .song-info {
    font-family: "Comfortaa", cursive;
    padding: 0 10px 10px 10px;
    text-align: center;

    .song {
      font-size: 1em;
      font-weight: bold;

      a {
        color: #181425!important;
      }
    }
    

    .author {
      font-size: 0.8em;
      color: var(--theme-color-dark);
    }
  }

  .player-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 0 10px;
  }

  .ytp-title {
    display: none !important;
  }

  #player {
    width: 100%;
    height: 100%;
    background-color: var(--theme-color);
    // display: none;
  }

  .duration-range {
    width: 100%;
    margin: 0;
    padding: 0 5px;
    padding-top: 5px;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
  }

  .duration-range::-moz-range-track    {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
      height: 5px;
      cursor: pointer;
      background: #181425;
      border-radius: 5px;
  }

  .duration-range::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
      height: 5px;
      cursor: pointer;
      background: #181425;
      border-radius: 5px;
  }

  
.duration-range::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
      background: var(--theme-color);
      border: solid 2px #181425;
      border-radius: 50px;
      width:10px;
      height: 10px;
    }

    
  .duration-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
      background: var(--theme-color);
      border: solid 2px #181425;
      border-radius: 50px;
      width:15px;
      height: 15px;
      margin-top: -5.5px;
    }

    .hidden {
      display: none;
    }

    .active {
      display: block;
    }
</style>
