<script>
  import { onMount } from 'svelte';

  let toggleDetails = true;
  export let buttonLabel = "Details";

  let uniqueId;

  // Generate a unique ID for each instance of the component
  onMount(() => {
    uniqueId = `detail-paragraph-${Math.random().toString(36).substring(2, 9)}`;
  });

  const onClickToggleDetails = () => {
    toggleDetails = !toggleDetails;
    jQuery(`#${uniqueId}`).slideToggle();
  };
</script>

{#if $$slots.default}
  <div class="details">
    <div class="toggle-details" on:click={onClickToggleDetails}>
      <i class="fa-solid fa-chevron-{toggleDetails ? 'up' : 'down'}" />
      <span class="details-label">{buttonLabel}</span>
    </div>
    <p id={uniqueId}>
      <slot />
    </p>
  </div>
{/if}

<style>
  .details {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .details p {
    background-color: #fff8e9;
    color: #181425;
    padding: 15px;
    font-size: 0.8em;
    margin-bottom: 0;
    border-top: solid 4px #181425;
    display: none;
  }

  .toggle-details {
    background-color: var(--theme-color);
    color: var(--theme-font-color);
    padding: 5px 15px 5px 15px;
    margin-left: 10px;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    border: solid 4px #181425;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: -4px;
  }

  .toggle-details:hover {
    background-color: var(--theme-color-dark);
  }

  .details-label {
    font-size: 0.9em;
    padding-left: 8px;
  }
</style>
