<script>
    import  About  from '../Content/Notepad/About.svelte';
    import  Readme  from '../Content/Notepad/Readme.svelte';
    import  Todo  from '../Content/Notepad/Todo.svelte';

    export let exportPage = "";

    const componentsMap = {
        "note_about": About,
        "note_readme": Readme,
        "note_todo": Todo,
    }



</script>


<div class="wrapper">
    <svelte:component this={componentsMap[exportPage]} />
</div>

<style>
    .wrapper {
        padding: 20px;
        height: 100%;
        max-width: 800px;
        margin: 0 auto;
        overflow-y: scroll;
    }
</style>