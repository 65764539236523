<script>
  const mutualsVullage = [
    {
      "name": "Sakura Dreams",
      "url": "https://sakuradreams.neocities.org/",
      "image": "img/buttons/sakuradreams.gif"
    },
    {
      "name": "Pixeliana",
      "url": "https://pixeliana.neocities.org/",
      "image": "img/buttons/pixelianabutton1.gif"
    },
    {
      "name": "Lakestheguy",
      "url": "https://lakestheguy.neocities.org/",
      "image": "img/buttons/lakestheguy.png"
    },
    {
      "name": "oyasumimi",
      "url": "https://oyasumimi.neocities.org/",
      "image": "img/buttons/oyasumimi.gif"
    },
    {
      "name": "Layercake",
      "url": "https://layercake.nekoweb.org/",
      "image": "img/buttons/layercakebutton.gif"
    },
    {
      "name": "jutipia",
      "url": "https://jutipia.neocities.org/",
      "image": "img/buttons/jutipia.png"
    },
    {
      "name": "Misfitkittykat",
      "url": "https://misfitkittykat.neocities.org/",
      "image": "img/buttons/misfitkittykat.gif"
    },
    {
      "name": "The Iron Ragdoll",
      "url": "https://tofutush.neocities.org/",
      "image": "img/buttons/ironragdoll.gif"
    },
    {
      "name": "Welcome to here",
      "url": "https://sillyspecimen.neocities.org/",
      "image": "img/buttons/welcometohere.gif"
    },
    {
      "name": "Final Fantasy IX",
      "url": "https://finalfantasyix.neocities.org/",
      "image": "img/buttons/ix_button.gif"
    },
    {
      "name": "Acetaminophriends",
      "url": "https://acetaminophriends.neocities.org/",
      "image": "img/buttons/Wire_88x31.png"
    },
    {
      "name": "Onio Cafe",
      "url": "https://onio.cafe/",
      "image": "img/buttons/oniocolor.gif"
    },
    {
      "name": "Leefy Moon",
      "url": "https://leefymoon.nekoweb.org/",
      "image": "https://files.catbox.moe/7ww8ux.png"
    },
    {
      "name": "Nilly",
      "url": "https://nilistisk.neocities.org/",
      "image": "img/buttons/nilistisk.gif"
    },
    {
      "name": "Darkend",
      "url": "https://darkendstars.neocities.org/",
      "image": "img/buttons/darkend.gif"
    },
    {
      "name": "MLE-S Paint",
      "url": "https://mle-s-paint.neocities.org/",
      "image": "img/buttons/MLESLINK2.gif"
    },
    {
      "name": "Humanfinny",
      "url": "https://humanfinny.neocities.org/",
      "image": "img/buttons/park/humanfinny.jpg"
    },
    {
      "name": "Pluto22",
      "url": "https://pluto22.neocities.org/",
      "image": "img/buttons/park/pluto22.png"
    },
    {
      "name": "Rogue Pebble",
      "url": "https://roguepebble.neocities.org/",
      "image": "img/buttons/ROGUEPEBBLE-BUTTON.GIF"
    },
    {
      "name": "GoreKytti",
      "url": "https://gorekytti.neocities.org/",
      "image": "img/buttons/gorekytti.gif"
    },
    {
      "name": "Rhinedottir",
      "url": "https://rhinedottir.neocities.org/",
      "image": "https://rhinedottir.neocities.org/rhinedottir_button.png"
    },
    {
      "name": "Daniele's Website",
      "url": "https://daniele63.com/",
      "image": "img/buttons/park/danieles_button.png"
    },
    {
      "name": "MauveMystic's dream island",
      "url": "https://mauvemystic.neocities.org/",
      "image": "img/buttons/mauvemystic.png"
    },
  ]

  const park = [
  {
    "name": "Pollygon",
    "url": "https://pollygon.dev/",
    "image": "img/buttons/park/pollygon-2.gif"
  },
  {
    "name": "miserabledolly",
    "url": "https://miserabledolly.net/",
    "image": "img/buttons/park/MiserableDolly.gif"
  },
  {
    "name": "Appledust",
    "url": "https://www.lejlart.com/apple.html",
    "image": "img/buttons/park/appledust.gif"
  },
  {
    "name": "Dog House",
    "url": "https://nenrikido.neocities.org/",
    "image": "img/buttons/park/dh-button2.gif"
  },
  {
    "name": "Nenrikido",
    "url": "https://dog-house.neocities.org/",
    "image": "img/buttons/park/nenrikido_button.gif"
  },
  {
    "name": "Pixalina",
    "url": "https://pixalina.neocities.org/",
    "image": "img/buttons/park/pixalina.gif"
  },
  {
    "name": "Cyberstheb",
    "url": "https://cyberstheb.neocities.org/",
    "image": "img/buttons/park/stheb.gif"
  },
  {
    "name": "Celli",
    "url": "https://celli.neocities.org/",
    "image": "img/buttons/park/cellibutton.png"
  },
  {
    "name": "Frajola",
    "url": "https://frajola.neocities.org/",
    "image": "img/buttons/park/frajola.gif"
  },
  {
    "name": "DuckDev",
    "url": "https://duckdev.neocities.org/",
    "image": "img/buttons/park/duckdev.gif"
  },
  {
    "name": "burgeritchi",
    "url": "https://burgeritchi.neocities.org/",
    "image": "img/buttons/park/burgeritchi.webp"
  },
  {
    "name": "Winbows XP",
    "url": "https://winbows.neocities.org/",
    "image": "img/buttons/park/winbows-btn.png"
  },
  {
    "name": "Rice Place",
    "url": "https://rice.place/",
    "image": "https://rice.nekoweb.org/button/riceplace.png"
  },
  {
    "name": "LilithDev",
    "url": "https://lilithdev.neocities.org/",
    "image": "img/buttons/park/lilithdevbtn.gif"
  },
  {
    "name": "Pastel Hell",
    "url": "https://pastelhello.com/",
    "image": "img/buttons/park/pastelhell.png"
  },
  {
    "name": "The Absolute Realm",
    "url": "https://theabsoluterealm.com/",
    "image": "img/buttons/park/arbutton.gif"
  },
  {
    "name": "Aegi's Cafe",
    "url": "https://aegi.neocities.org/",
    "image": "img/buttons/park/aegi_button.gif"
  },
  {
    "name": "Irony Machine",
    "url": "https://irony-machine.neocities.org",
    "image": "img/buttons/park/irony-machine.png"
  },
  {
    "name": "Cloverbell",
    "url": "https://cloverbell.neocities.org/",
    "image": "img/buttons/park/cloverbell.gif"
  },
  {
    "name": "Lintnaya",
    "url": "https://lint.neocities.org/",
    "image": "img/buttons/park/lintnaya.png"
  },
  {
    "name": "Ita Toys",
    "url": "https://ita.toys/",
    "image": "img/buttons/park/ita.toys.png"
  },
  {
    "name": "Ash Kyd",
    "url": "https://ash.ms",
    "image": "https://ash.ms/wp-content/button.gif"
  },
  {
    "name": "sweet-pea",
    "url": "https://sweet-pea.neocities.org",
    "image": "img/buttons/park/sweetpea.gif"
  },
]

function chunkArray(array, size) {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
      array.slice(i * size, i * size + size)
    );
  }

  let firstRow = mutualsVullage.slice(0, 3);
  let remainingSites = mutualsVullage.slice(3);
  let houseGroups = chunkArray(remainingSites, 6); // Groups of 6 (2 rows of 3)

function getRandomObject() {
  const objects = [
    "img/buttons/layout/flower-vase.png",
    "img/buttons/layout/trashcan.png",
  ];
  
  return objects[Math.floor(Math.random() * objects.length)];
}
</script>

<div class="container">
  <h1>kuroiOS neighborhood</h1>
  <p>
    If you link my button to your website, let me know so you can have your very
    own home in the vullage!
  </p>

  <div class="town">
    <div class="corner-decorations">
      <img src="img/buttons/layout/tree-trio.png" alt="Tree trio" />
      <div class="sign">
        <div class="title">Mutuals Vullage</div>
        <div class="subtitle">kuroi's button:</div>
        <img src="img/button1.png" alt="Kuroi's button" />
      </div>
    </div>
  
    <!-- First row (3 houses) -->
    <div class="house-row">
      <div class="object-lightpost">
        <img src="img/buttons/layout/lightpost.png" alt="Light post" />
      </div>
  
      {#each firstRow as site, i}
        <div class="house">
          <div class="site-button">
            <a href={site.url} target="_blank">
              <img src={site.image} alt={site.name} />
            </a>
          </div>
          <img src="img/buttons/layout/house.png" alt="House" />
        </div>
  
        {#if i < firstRow.length - 1}
          <img class="object" src={i % 2 === 0 ? "img/buttons/layout/flower-vase.png" : "img/buttons/layout/trashcan.png"} alt="Decoration" />
        {/if}
      {/each}
  
      <div class="object-lightpost">
        <img src="img/buttons/layout/lightpost.png" alt="Light post" />
      </div>
    </div>
  
    <!-- First street -->
    <div class="street">
      <div class="street-middle"></div>
    </div>
  
    <!-- Remaining groups (2 rows per set) -->
    {#each houseGroups as group}
  {#each chunkArray(group, 3) as row, rowIndex}
    <div class="house-row" class:spacer={rowIndex === 1}>
      <div class="object-lightpost">
        <img src="img/buttons/layout/lightpost.png" alt="Light post" />
      </div>

      {#each row as site, i}
        <div class="house">
          <div class="site-button">
            <a href={site.url} target="_blank">
              <img src={site.image} alt={site.name} />
            </a>
          </div>
          <img src="img/buttons/layout/house.png" alt="House" />
        </div>

        {#if i < row.length - 1}
          <img class="object" src={getRandomObject()} alt="Decoration" />
        {/if}
      {/each}

      <div class="object-lightpost">
        <img src="img/buttons/layout/lightpost.png" alt="Light post" />
      </div>
    </div>
  {/each}

  <!-- Street after every two rows -->
  <div class="street">
    <div class="street-middle"></div>
  </div>
{/each}
  </div>

  <div class="park">
    <div class="fences">
      <img src="img/buttons/layout/fence.png" alt="Fence" />
      <img src="img/buttons/layout/fence.png" alt="Fence" />
    </div>
    <div class="corner-decorations">
      <div class="sign">
        <div>Cool websites Park :D</div>
      </div>
      <img src="img/buttons/layout/tree-trio.png" alt="Tree trio" />
    </div>
    <div class="button-list">
      {#each park as site}
      <a href={site.url} target="_blank">
        <img src={site.image} alt={site.name} />
      </a>      
      {/each}
    </div>

    <div class="corner-decorations">
      <img src="img/buttons/layout/tree-trio.png" alt="tree-trio" />
      <img src="img/buttons/layout/tree-trio.png" alt="tree-trio" />
    </div>
  </div>
</div>

<style lang="scss">
  .container {
    color: #fff;
    background-color: #000;
    min-width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: scroll;
    image-rendering: pixelated;
  }

  a img {
    width: 88px;
    height: 31px;
  }

  h1 {
    font-family: "EnterCommand" !important;
  }

  p {
    font-family: "Apple" !important;
    font-size: 0.6em;
  }

  .town {
    padding: 50px 0;

    .sign {
      margin-left: auto;
      margin-top: 0;
      margin-bottom: 50px;
      font-size: 1.2em;
      text-align: center;
      padding-bottom: 50px;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        width: 100%;
      }

      .subtitle {
        font-family: "Apple";
        font-size: 0.4em;
        font-weight: normal;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .house-row {
    display: flex;
    justify-content: center;
    align-items: end;
    margin-top: -30px;
  }

  .spacer {
    padding-top: 50px;
  }

  .house {
    position: relative;
  }

  .site-button {
    position: absolute;
    top: 1px;
    left: 8px;
  }

  .site-button img {
    image-rendering: auto;
  }

  .object {
    padding: 2px 5px;
  }

  .object-lightpost {
    padding: 0px 15px;
    position: relative;
  }

  .street {
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff;
    margin: 15px 0px;
    width: 100%;
  }

  .street-middle {
    border-top: solid 1px #fff;
    width: 100%;
    margin-top: 3px;
    height: 25px;
    border-bottom: dashed 3px #fff;
    margin-bottom: 25px;
  }

  .fences {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  .button-list {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    padding: 50px 30px;
    gap: 3px;
  }

  .corner-decorations {
    display: flex;
    justify-content: space-between;
    margin-top: -50px;
    align-items: end;
  }

  .sign {
    padding: 10px 30px 35px 33px;
    font-family: "EnterCommand";
    background-image: url("../img/buttons/layout/sign.png");
    background-repeat: no-repeat;
    background-size: auto;
    width: 207px;

    text-align: center;
    font-size: 1.4em;
    display: flex;
    align-items: center;
    line-height: 0.8;
    font-weight: bold;
  }

  .park .sign {
    margin-top: -40px;
    padding: 28px 30px 57px 33px;
  }
</style>
