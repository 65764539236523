<script>
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";
  import ButtonRound from "../UI/ButtonRound.svelte";

  export let contentData = null;

  let currentPage=0;

  onMount(() => {
    chattable.initialize({
      stylesheet : "css/chattable.css"
    });
  });
    

</script>

<div class="wrapper">
    <div class={currentPage === 0 ? 'rules active' : 'rules hidden'}>
      <h4> Da Rules!</h4>
      
      <ul>
          <li>
              <strong>Be Respectful – </strong>No hate speech, harassment, or personal attacks. Treat everyone with kindness.
          </li>
          <li>
              <strong>No NSFW – </strong> Sharing or discussing adult and/or violent content is not allowed.
          </li>
          <li>
              <strong>No Spam – </strong> Do not flood the chat.
          </li>
          <li>
              <strong>Respect Privacy – </strong> Do not share or ask for personal information.
          </li>
          <li>
              <strong>Have a great time! – </strong> Use the chat to have fun and learn new things :)
          </li>
      </ul>

      <div class="center">
        <div class="topic-wrapper">
          <div class="round-border topic-day">
            <p class="title">Topic of the day:</p>
            <p>Indie games that deserve more love—any hidden gems to share?</p>
          </div>
          <ButtonRound on:click={() => currentPage = 1}>Enter the Chat!</ButtonRound>

        </div>
        <div class="buttons-wrapper">
            <h6>Here some other cool chats to hang out</h6>
            <div>
              <a href="https://message.neocities.org/" target="_blank"><img src="img/buttons/night.gif" alt="Nightscape" /></a>
              <a href="https://onio.cafe/" target="_blank"><img src="img/buttons/oniocolor.gif" alt="Onio Cafe" /></a>

            </div>
          </div>
          <h6>This chatbox is powered by Chattable!</h6>
          <a href="https://iframe.chat/" target="_blank"><img src="https://iframe.chat/chattable-alt.gif" title="Add a stream chat to your website free with Chattable" alt="Chattable" /></a>
      </div>

  </div>

  <div class={currentPage === 1 ? 'active' : 'hidden'} style="width: 100%; height: 100%; position: relative">
    <button class="back" on:click={() => currentPage = 0}><i class="fa-solid fa-home"></i>Back</button>
    <iframe src='https://iframe.chat/embed/alternate?chat=58163492' id='chattable' frameborder='none' ></iframe>
  </div>
</div>

<style lang="scss">
  .buttons-wrapper {
    margin: 20px 0;
    display:flex;
    flex-direction: column;
    align-items: center;;
  }
    .wrapper {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;

        background-image: linear-gradient(
        30deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        150deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        30deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        150deg,
        #fff0df 12%,
        transparent 12.5%,
        transparent 87%,
        #fff0df 87.5%,
        #fff0df
      ),
      linear-gradient(
        60deg,
        #fff0df77 25%,
        transparent 25.5%,
        transparent 75%,
        #fff0df77 75%,
        #fff0df77
      ),
      linear-gradient(
        60deg,
        #fff0df77 25%,
        transparent 25.5%,
        transparent 75%,
        #fff0df77 75%,
        #fff0df77
      );
    background-size: 80px 140px;
    background-position:
      0 0,
      0 0,
      40px 70px,
      40px 70px,
      0 0,
      40px 70px;
    background-color: #fff8e9;
    }

    iframe {
        width: 100%;
        max-width:680px;
        height: 100%;
        border: none;
        margin: 0 auto;
    }

    label {
        font-size: .8em
    }

    p {
      text-align: center;
    }

    h3, h4, h6 {
       color: var(--theme-color-dark);
       margin-top: 5px;
       text-align: center;
    }

    ul {
        font-size: .8em;
        padding: 10px;
        border: dashed 3px var(--theme-color-dark);
        border-radius: 20px;
        padding-left: 2em;
    }

    .center {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

  .active {
    display:block !important;
  }

  .hidden {
    display:none!important;
  }

  .rules {
    padding: 10px;
    margin: auto 0;
    min-height: 100%;
    overflow-y: scroll;
    
  }

  .back {
    color: #FFF;
    position: absolute;
    background: none;
    border:none;
    font-size: .7em;
    padding: 0;
    font-family: 'Comfortaa', sans-serif;
    cursor:pointer;
    padding: 6px 5px;
    line-height: 0;
    margin-bottom: 0;

    i {
      margin-right: 5px;
    }
  }

  .topic-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    p {
      margin: 0;
    }

    .topic-day {
      background:#FFF;
      padding: 10px;
      font-size:.8em;
      flex-grow: 1;
      width: 100%;

      .title {
        font-weight: bold;
        color: var(--theme-color-dark);
        font-family: "Comfortaa", sans-serif;
      }
    }
  }
</style>