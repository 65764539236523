<script>
  import Icon from "../UI/icon.svelte";
  import modals from "../Helpers/modal-store";
  import { createEventDispatcher, onMount } from "svelte";
  import ButtonRound from "../UI/ButtonRound.svelte";

  import supabase from "../config/supabaseClient";

  const dispatch = createEventDispatcher();

  export let contentData = null;

  let activeSideMenu = "root";

  let allFolders = {};
  let allFiles = {};
  let currentFolder = {};
  let currentFolderFiles = [];
  let currentFolderFolders = [];
  let folderName = "";

  let folderHistory = ["root"];
  let historyPosition = 0;

  async function fetchFolderData() {
    const { data: folders, error: folderError } = await supabase
      .from("folders")
      .select("*")
      .eq("parent_id", "root"); // Load root folders (parent_id = null)

    if (folderError) {
      console.error("Error fetching folders", folderError);
      return;
    }

    folderTree = folders; // Set the entire folder tree structure
    console.log("Folder tree", folders);
  }

  onMount(async () => {
    // Getting all folders and files to reduce api calls
    const { data: folders, error: folderError } = await supabase
      .from("folders")
      .select("*");

    if (folders) {
      allFolders = folders;
      console.log(allFolders);
    }

    const { data: files, error: fileError } = await supabase
      .from("files")
      .select("*");
    if (files) {
      allFiles = files;
      console.log(allFiles);
    }

    currentFolder = allFolders.find((folder) => folder.id === "root");
    currentFolderFiles = allFiles.filter((file) => file.folder_id === "root");
    currentFolderFolders = allFolders.filter(
      (folder) => folder.parent_id === "root"
    );

    folderName = currentFolder.name;
  });

  function onClickFolder(folder) {
    const folderId = folder.id;
    currentFolderFiles = allFiles.filter((file) => file.folder_id === folderId);
    currentFolderFolders = allFolders.filter(
      (folder) => folder.parent_id === folderId
    );
    currentFolder = folder;
    folderName = folder.name;

    folderHistory = folderHistory.slice(0, historyPosition + 1);
    folderHistory.push(folderId);
    historyPosition++;
  }

  function navigate(direction) {
    historyPosition += direction;
    if (
      folderHistory[historyPosition] == "starred" ||
      folderHistory[historyPosition] == "recent"
    ) {
      onClickSidebar(folderHistory[historyPosition], true);
      return;
    }
    const folderId = folderHistory[historyPosition];
    currentFolder = allFolders.find((folder) => folder.id === folderId);
    currentFolderFiles = allFiles.filter((file) => file.folder_id === folderId);
    currentFolderFolders = allFolders.filter(
      (folder) => folder.parent_id === folderId
    );

    folderName = currentFolder.name;
  }

  function onClickFile(file) {
    if (file.type == "img") {
      let data = {
        file: file,
        imgArray: currentFolderFiles.filter((file) => file.type == "img"),
      };
      dispatch("fileClick", data);
      openModal("gallery");
      return;
    }

    if (file.type == "exe") {
      let data = file;
      dispatch("fileClick", data);
      openModal("exe");
      return;
    }

    if(file.type == "notepad") {
      let data = file;      
      dispatch("fileClick", data);
      openModal("notepad", data.id);
      return;

    }
  }

  function openModal(id, shortcut = null) {
    modals.openModal(id, shortcut);
  }

  function onClickSidebar(menu, isNav = false) {
    activeSideMenu = menu;
    if (isNav == false) {
      if (folderHistory[historyPosition] == menu) {
        return;
      }
      historyPosition = historyPosition + 1;
      resetHistory(0, historyPosition);
      folderHistory.push(menu);
    }
    switch (menu) {
      case "root":
        folderName = "Root Folder";
        currentFolder = allFolders.find((folder) => folder.id === "root");
        currentFolderFiles = allFiles.filter(
          (file) => file.folder_id === "root"
        );
        currentFolderFolders = allFolders.filter(
          (folder) => folder.parent_id === "root"
        );
        break;
      case "starred":
        folderName = "Starred";
        currentFolderFiles = allFiles.filter((file) =>
          file.tags.includes("starred")
        );
        currentFolderFolders = allFolders.filter((folder) =>
          folder.tags.includes("starred")
        );
        break;
      case "recent":
        const today = new Date();
        const cutoffDate = new Date();
        cutoffDate.setDate(today.getDate() - 7);
        folderName = "Recent";
        currentFolderFiles = allFiles.filter((file) => {
          const recordDate = new Date(file.created_at);
          return recordDate >= cutoffDate;
        });
        currentFolderFolders = allFolders.filter((folder) =>{
          const recordDate = new Date(folder.created_at);
          return recordDate >= cutoffDate;
        });
        break;
    }
  }

  function resetHistory(begin, end) {
    if (folderHistory.length >= historyPosition + 1) {
      folderHistory = folderHistory.slice(begin, end);
    }
  }
</script>

<div class="folder-wrapper">
  <div class="sidebar">
    <div class="sidebar-buttons">
      <ButtonRound
        small={true}
        disabled={historyPosition == 0}
        on:click={() => navigate(-1)}
      >
        <i class="fa-solid fa-arrow-left fa-fw" />
      </ButtonRound>
      <ButtonRound
        small={true}
        disabled={historyPosition >= folderHistory.length - 1}
        on:click={() => navigate(1)}
      >
        <i class="fa-solid fa-arrow-right fa-fw" />
      </ButtonRound>
    </div>
    <ul class="sidebar-menu">
      <li
        class="sidebar-menu-item"
        class:active={activeSideMenu == "root"}
        on:click={() => onClickSidebar("root")}
      >
        <i class="far fa-folder" />
        <span class="menu-item-label"> Root Folder </span>
      </li>
      <li
        class="sidebar-menu-item"
        class:active={activeSideMenu == "starred"}
        on:click={() => onClickSidebar("starred")}
      >
        <i class="far fa-star" />
        <span class="menu-item-label"> Starred </span>
      </li>
      <li
        class="sidebar-menu-item"
        class:active={activeSideMenu == "recent"}
        on:click={() => onClickSidebar("recent")}
      >
        <i class="far fa-clock" />
        <span class="menu-item-label"> Recent </span>
      </li>
    </ul>
  </div>

  <div class="folder-content-wrapper">
    <div class="folder-title">
      <h3>{folderName}</h3>
    </div>
    <div class="folder-content">
      {#if currentFolderFolders.length > 0}
        {#each currentFolderFolders as folder, i}
          <Icon
            icon={folder.type}
            on:dblclick={() => onClickFolder(folder)}
            label={folder.name}
          ></Icon>
        {/each}
      {/if}
      {#if currentFolderFiles.length > 0}
        {#each currentFolderFiles as file, i}
          <Icon
            icon={file.type}
            on:dblclick={() => onClickFile(file)}
            label={file.name}
          ></Icon>
        {/each}
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .folder-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .sidebar {
    background-color: #eee;
    flex-basis: 140px;
    flex-shrink: 0;
    border-right: solid 2px var(--theme-color);
  }

  ul.sidebar-menu {
    list-style-type: none;
    padding-left: 0;
  }

  ul.sidebar-menu li.sidebar-menu-item {
    font-size: 0.8em;
    padding: 10px;
    cursor: pointer;
    font-family: "Source Sans Pro", sans-serif;

    &::hover {
      background-color: #e3e3e3;
    }

    &:active,
    .active {
      background-color: var(--theme-color);
      color: var(--theme-font-color);
    }
  }

  ul.sidebar-menu li.sidebar-menu-item:active,
  ul.sidebar-menu li.sidebar-menu-item.active {
    background-color: var(--theme-color);
    color: var(--theme-font-color);
  }

  .sidebar-buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 15px;
    gap: 7px;
  }

  .menu-item-label {
    padding-left: 7px;
    font-weight: 500;
  }

  .folder-content-wrapper {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }

  .folder-content {
    padding: 2px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fit, 110px);
    width: 100%;
  }

  .folder-title {
    padding: 10px;
    font-weight: bold;
    text-transform: capitalize;
  }

  
  @media screen and (max-width: 450px ) {
   .folder-wrapper {
    flex-direction: column;
   }

   .sidebar {
    display: flex;
    flex-direction: row;
    flex-basis: auto;
    border-right: none;
    border-bottom: solid 2px var(--theme-color);
    
    .sidebar-menu {
     display: flex;
     margin-bottom: 0;


     .sidebar-menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .menu-item-label {
        padding-left: 0;
      }
     }
    }
   }

  }
</style>
