<script>
  import ButtonRound from "../../UI/ButtonRound.svelte";
  import modals from "../../Helpers/modal-store";
  import { fade } from 'svelte/transition';

  let showModal = false;

  function openGuestbook() {
    modals.openModal('guestbook');
  }
</script>

<div class="wrapper">
  <iframe
    src="https://www.youtube.com/embed/W2Y8afd31tM?si=QKfzrUR2L9WzDoT1&amp;controls=0&autoplay=1"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  ></iframe>

  <div class="secret">
    <ButtonRound circle={true} on:click={() => showModal=true}>
      <i class="fa-solid fa-award" />
    </ButtonRound>
    <h3>You found the secret!</h3>
  </div>

  {#if showModal}
  <div class="modal" on:click|self={() => showModal=false} transition:fade>
    <div class="modal-box round-border">
      <h4>You found a secret!</h4>
      <p>
        Comment on the <span class="link" on:click={openGuestbook}>guestbook</span> telling you found the Disco Rave secret (but don't
        spoil where it is!). The first three will have their website button added to the podium here :D
      </p>
      <div class="podium">
        <div class="second">
            <i class="fa-solid fa-trophy fa-fw"></i>
            <a href=" https://humanfinny.neocities.org/ " target="_blank" rel="noopener">
                <img src="img/buttons/park/humanfinny.jpg" alt="HumanFinny" />
            </a>
            <div class="found">
                Found in <br> March 07 2025
            </div>
        </div>
        <div class="first">
            <i class="fa-solid fa-trophy fa-fw"></i>
            <a href="https://pluto22.neocities.org/" target="_blank" rel="noopener">
                <img src="img/buttons/park/pluto22.png" alt="Pluto22" />
            </a>
            <div class="found">
                Found in <br> March 07 2025
            </div>

        </div>
        <div class="third">
            <i class="fa-solid fa-trophy fa-fw"></i>
            <a href="https://oyasumimi.neocities.org/" target="_blank" rel="noopener">
                <img src="img/buttons/oyasumimi.gif" alt="Oyasumimi" />
            </a>
            <!-- <div style="width: 88px; height: 31px"></div> -->
            <div class="found">
                Found in <br> March 07 2025
            </div>
        </div>
      </div>
    </div>
  </div>
  {/if}
</div>

<style lang="scss">
  iframe {
    width: 100%;
    height: 100%;
  }

  .link {
    color: var(--theme-color-dark);    
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .secret {
    position: absolute;
    left: 5px;
    bottom: 5px;
    width: 272px;
    text-align: left;
    color: #fff8e9;
    display: flex;
    align-items: center;

    h3 {
      margin-bottom: 0;
      text-shadow: 3px 3px 0px #181425;
    }

    i {
      padding: 0 3px;
    }
  }

  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-box {
      background-color: #fff8e9;
      padding: 20px;
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }

    h3 {
      text-shadow: none;
      font-weight: bold;
    }

    p {
        font-size: .8em;
    }
  }
  h3 {
    margin-bottom: 0;
    text-shadow: 3px 3px 0px #181425;
  }

  .podium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    line-height: 0;
    gap: 20px;
    .second, .first, .third {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:10px;
    }

    .first {
      color: gold;
      font-size: 3em;
      .box {
        background-color: gold;
        height: 50px;
      }
    }

    .second {
      color: silver;
      font-size:2.2em;
    }

    .third {
      color: #e07f1f ;
      font-size: 1.9em;
    }        

    .found {
        font-size:10px;
        font-weight: bold;
        line-height: 1.2;
    }

    img {
        width: 88px;
        height: 31px;
    }

  }
</style>
