<script>
  import { onMount } from "svelte";
  import * as THREE from "three";
  import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
  import GUI from "lil-gui";
  import { gsap, Power4, Bounce } from "gsap";

  import { debounce } from "lodash";

  let canvas;

  // Define window size
  const sizes = {
    width: 500,
    height: 500,
  };

  onMount(() => {
    const parentWindow =
      document.querySelector(".canvas-wrapper").parentElement.parentElement
        .parentElement;

    const handleResize = debounce(() => {
      sizes.width = parentWindow.clientWidth;
      sizes.height = parentWindow.clientHeight;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
    }, 100);

    const windowSizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        handleResize();
      });
    });
    windowSizeObserver.observe(parentWindow);

    // Define canvas object
    canvas = document.querySelector(".webgl");

    // Debug
    const gui = new GUI({
      container: document.querySelector(".canvas-wrapper"),
    });

    const parameters = {
      spin: () => {
        gsap.to(cube1.rotation, {
          duration: 1,
          y: cube1.rotation.y + Math.PI * 2,
        });
      },
      jump: () => {
        gsap.to(cube1.position, { duration: 0.8, y: 1, ease: Power4.easeNone });
        gsap.to(cube1.position, {
          delay: 0.8,
          duration: 1,
          y: 0,
          ease: Bounce.easeOut,
        });
      },
    };

    // Scene
    const scene = new THREE.Scene();

    // Objects - geometries
    const cube1 = new THREE.Mesh(
      new THREE.BoxGeometry(1, 1, 1),
      new THREE.MeshBasicMaterial({ color: 0xff0000 })
    );
    scene.add(cube1);

    // debug
    gui.add(cube1.position, "x", -3, 3, 0.01);
    gui.add(cube1.position, "z", -3, 3, 0.01);

    gui.add(cube1.position, "y").min(-3).max(3).step(0.01).name("elevation");

    gui.add(cube1, "visible");

    gui.add(cube1.material, "wireframe");

    gui.addColor(cube1.material, "color");

    gui.add(parameters, "spin");
    gui.add(parameters, "jump");

    // Camera
    const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height);
    camera.position.z = 4;
    scene.add(camera);

    // Renderer
    const renderer = new THREE.WebGLRenderer({ canvas: canvas });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const controls = new OrbitControls(camera, canvas);

    // Loop function
    const clock = new THREE.Clock();

    let tick = () => {
      //Get delta time
      const elapsedTime = clock.getElapsedTime();

      // Update Objects

      // Update renderer
      renderer.render(scene, camera);

      window.requestAnimationFrame(tick);
    };

    tick();
  });
</script>

<div class="canvas-wrapper">
  <canvas class="webgl" />
</div>

<style>
  .canvas-wrapper {
    position: relative;
    background-color: black;
  }

  :global(.lil-gui) {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
