<script>
  import { createEventDispatcher, onMount } from "svelte";
  import moment from "moment";
  import "moment/locale/pt-br";

  moment.locale("en");
  let time = moment().format("MMM D, HH:mm");

  window.setInterval(() => {
    time = moment().format("MMM D, HH:mm");
  }, 1000);

  const dispatch = createEventDispatcher();

  function openSettings() {
    dispatch("openSettings", null);
  }

  function openTurnOff() {
    dispatch("openTurnOff", null);
  }
</script>

<div class="status-bar">
  <div />
  <div class="status-bar-clock">
    {time}
  </div>
  <div class="status-bar-buttons">
    <div class="status-bar-button" on:click={openSettings}>
      <i class="fas fa-cog" />
    </div>
    <div class="status-bar-button" on:click={openTurnOff}>
      <i class="fas fa-power-off" />
    </div>
  </div>
</div>

<style>
  .status-bar {
    background-color: rgba(24, 20, 37, 0.9);
    color: #fff;
    font-size: 0.8em;
    padding: 2px 10px;
    display: flex;
    justify-content: space-between;
    font-family: "Comfortaa", sans-serif;
    align-items: center;
    height: 30px;
  }

  .status-bar-buttons {
    display: flex;
  }

  .status-bar-button {
    padding: 0 8px;
    cursor: pointer;
    font-size: 1.2em;
  }
</style>
