<script>
  import { onMount } from 'svelte';


  import supabase from '../../../config/supabaseClient';
  export let refresh = null;
  export let src = null;

  src;
  refresh;
  let posts = [];
  let filteredPosts = [];
  let tags = [];

  onMount(() => {    
    const fetchPosts = async () => {
      const { data, error } = await supabase
        .from('kiwitter')
        .select()
        .order('created_at', { ascending: false });
      if (error) {
        console.log('Could not fetch posts', error);
        posts = []
      } 
      else {
        for (let post of data) {
          if (post.img) {
            const { data, error: urlError } = supabase
              .storage
              .from('kiwitter')
              .getPublicUrl(post.img);              
            if (error) {
              console.log('Could not fetch image URL', error);
            } else {              
              post.imgUrl = data.publicUrl;         
                   
            }
          }
        }
        posts = data;        
        filteredPosts = posts;
      }
    };
    fetchPosts();    
  })

  function filterBy(tag) {
    if(tag === "all") {
      filteredPosts = posts;
      return;
    }
    filteredPosts = posts.filter(post => post.tags.includes(tag));
  }
</script>

<div class="container">
  <header class="round-border">
    <div class="banner-container">
      <div class="banner"></div>
    </div>
    <div class="banner-profile-picture">
      <img src="img/avatar.jpg" alt="profile" class="round-border" />
    </div>
    <div class="header-info">      
      <button class="header-info-item" on:click={() => filterBy("all")}>
        <div class="title">ALL</div>
        <div class="content">{posts.length}</div>
      </button>
      <button class="header-info-item" on:click={() => filterBy("devlog")}>
        <div class="title">DEVLOG</div>
        <div class="content">{posts.filter(post => post.tags.includes("devlog")).length}</div>
      </button>
      <button class="header-info-item" on:click={() => filterBy("chatter")}>
        <div class="title">CHATTER</div>
        <div class="content">{posts.filter(post => post.tags.includes("chatter")).length}</div>
      </button>
      <button class="header-info-item" on:click={() => filterBy("quote")}>
        <div class="title"> QUOTE</div>
        <div class="content">{posts.filter(post => post.tags.includes("quote")).length}</div>
      </button>
    </div>
  </header>

  <div class="feed">
    {#each filteredPosts as post}
    <div class="post round-border">
      <div class="post-header">
        <div class="avatar">
          <img src="img/avatar.jpg" alt="profile" class="round-border" />
        </div>
        <div class="username">
          Kuroi :B
          <span class="date"> - {new Date(post.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span>
          <div class="tags">
            {#each post.tags as tag}
            <span class="tag">{tag}</span>
            {/each}
          </div>
          
        </div>

      </div>
      <div class="content">
        {#if post.title}
        <h5 class="post-title">{post.title}</h5>
        {/if}
        <p class="post-body" class:quote={post.tags.includes('quote')}>
          {@html post.body}
        </p>
        {#if post.img}
        <div class="post-picture">
          <img src="{post.imgUrl}" alt="{post.imgAlt}" />
        </div>
        {:else if post.youtube}
        <div class="youtube-frame">          
          <iframe class="round-border" width="560" height="315" src="{'https://www.youtube.com/embed/' + post.youtube}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        {/if}
      </div>
    </div>
    {/each}
</div>
</div>

<style lang="scss">
  .youtube-frame {
    display:flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  iframe {
    max-width: 100%;
    margin: 0 auto;
  }
  .quote {
    font-style: italic;
  }

  header {
    background: white;
    position: relative;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .banner-container {
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    
  }
  .banner-profile-picture img {
    width: 150px;
    position: absolute;
    bottom: -30px;
    left: 1em;
  }

  .header-info {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    font-family: "Comfortaa", cursive;
  }

  .header-info-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 10px;
    margin: 0 5px;
    border: none;
    background: none;
    border: 2px dotted transparent;
    transition: border .2s linear;

    &:hover {
      border: 2px dotted var(--theme-color);
    }

    .title {
      font-size: 0.5em;
    }

    .content {
      font-weight: bold;
      color: var(--theme-color-dark);
    }
  }

  .feed {
    .post {
      padding: 0.6em 0.9em;
      background: #fff;
      margin: 20px 0;

      .post-header {
        display: flex;
        gap: 20px;
        align-items: center;
        padding-bottom: 10px;
  border-bottom: dotted 3px lightgray;
  margin-bottom: 10px;
      }

      .post-title {
        color: var(--theme-color-dark);
        margin-top: 5px;
      }

      .avatar img {
        width: 50px;
        border: solid 2px #181425;
        border-radius: 5px;
        
      }

      .content {
        flex-grow: 1;
      }

      .username {
        font-weight: 700;
        font-family: "Comfortaa", cursive;
        padding-bottom: 4px;        
        margin-bottom: 3px;
        margin-top: 5px;
        .date {
          color: grey;
          font-weight: normal;
          font-size: 0.8em;
        }
      }

      .post-picture {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }

      .post-picture img {
        max-width: 100%;
        border-radius: 10px;
        max-height: 380px;
      }
    }
  }

  .tag {
    border: solid 1px var(--theme-color-dark);
    border-radius: 10px;
    font-size: .6em;
    color: var(--theme-color-dark);
    padding: 2px 4px;
    margin-right: 5px;
  }

  .banner {
    position: relative;
    width: 100%;
    height: 200px;
    animation: moveBackground 11s linear infinite;
    padding: 0.5em;
    border-radius: 8px 8px 0 0;
    background-color: #fff8e9;
    background-image: linear-gradient(
        30deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        150deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        30deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        150deg,
        var(--theme-color) 12%,
        transparent 12.5%,
        transparent 87%,
        var(--theme-color) 87.5%,
        var(--theme-color)
      ),
      linear-gradient(
        60deg,
        var(--theme-color-dark) 25%,
        transparent 25.5%,
        transparent 75%,
        var(--theme-color-dark) 75%,
        var(--theme-color-dark)
      ),
      linear-gradient(
        60deg,
        var(--theme-color-dark) 25%,
        transparent 25.5%,
        transparent 75%,
        var(--theme-color-dark) 75%,
        var(--theme-color-dark)
      );
    background-size: 80px 140px;
    background-position:
      0 0,
      0 0,
      40px 70px,
      40px 70px,
      0 0,
      40px 70px;
  }

  @keyframes moveBackground {
    0% {
      background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
    }
    100% {
      background-position: -200px -200px, -200px -200px, -160px -130px, -160px -130px, -200px -200px, -160px -130px;
    }
  }

  @media screen and (max-width: 490px) {
    .banner-profile-picture img {
      bottom: 99px;
    }

    .header-info {
      justify-content: space-around;
    }
  }
</style>
